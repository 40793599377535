import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from  '../../environments/environment';

import { tap } from 'rxjs/operators';
import { Employee } from '../models/student';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//  private baseUrl = `https://www.checkgrade.net/sjt/`;  // don't use local in case of cross domain or ip address
  //private baseUrl = `https://www.checkgrade.net/stw/`;  // don't use local in case of cross domain or ip address
  private baseUrl = environment.node_static_url;

  private apiUrl = `${this.baseUrl}/api/v1`;
  private loginUrl = `${this.apiUrl}/login`;
  private logoutUrl = `${this.apiUrl}/logout`;

  private employeeSubject: BehaviorSubject<Employee>;
  public employee: Observable<Employee>;

  //DI
  constructor(private http: HttpClient) {}

  login(credential):Observable<any>{
    this.storePassword(credential.username, credential.password);
    return this.http.post<any>(this.loginUrl, credential , {headers: this.headers});
  }

  //logout():Observable<void>{
  //  return this.http.get<void>(this.logoutUrl,  {headers: this.headers})
  //}

  isAuthenticated(): boolean{
    try {
      let authenInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
      //alert('Please login' + authenInfo.token);
      if (authenInfo.token != "") {
        this.loggedIn = true;
        return true;
      }
    } catch (error) {
      this.loggedIn = false;
      return false;
    }
    this.loggedIn = false;
    return false;
    // Check whether the token is expired and return
    // true or false

  }


  setAuthenticated(value: boolean){
    sessionStorage.removeItem(environment.localAuthenInfo);
  }

  private async storePassword(username: string, password: string): Promise<any> {
    if (!(window as any).PasswordCredential) {
      return Promise.resolve();
    }

    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username
    });
    return (navigator as any).credentials.store(cred);
  }

  private loggedIn = false;
  logout(): Observable<void> {
    return this.http
      .get<void>(this.logoutUrl, { headers: this.headers })
      .pipe(tap(() => {
        this.loggedIn = false;
        this.setAuthenticated(false);
        this.employeeSubject.next(null);
      }))
  }


  
}
