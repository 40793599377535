
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';

import { Student } from '../models/student';
import { environment } from '../../environments/environment';

@Injectable()
export class WebnotificationService {
  private VAPID_PUBLIC_KEY = environment.vapid_public_key;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //private baseUrl = `https://www.checkgrade.net/sjt/`;  // don't use local in case of cross domain or ip address
 // private baseUrl = `https://www.checkgrade.net/stw/`;  // don't use local in case of cross domain or ip address
  private baseUrl = environment.node_static_url;

  private apiUrl = `${this.baseUrl}/api/v1`;
  private subscribeUrl = `${this.apiUrl}/noti/subscribe`;
  private sendUrl = `${this.apiUrl}/noti/subscribe`;

  nStudent: Student;

  constructor(private http: HttpClient,
    private swPush: SwPush) {

  }

  subscribeToNotification() {

    this.swPush.requestSubscription({ //emit push subscription object
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.addPushSubscriber(sub))
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  subscribeToNotificationForStudent(student: Student) {

    this.swPush.requestSubscription({ //emit push subscription object
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.addPushSubscriberForStudent(sub, student))
      .catch(err => console.error("Could not subscribe to notifications", err));
  }


  addPushSubscriber(sub: any) {
    this.nStudent = new Student();
    let authInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
    this.nStudent.student_id = authInfo.student_id;
    this.nStudent.first_name = authInfo.first_name;
    this.nStudent.last_name = authInfo.last_name;

    this.http.post(this.subscribeUrl, 
      { notification : sub , student : this.nStudent }
      , { headers: this.headers })
      .subscribe();
  }

  addPushSubscriberForStudent(sub: any, myStudent: Student) {
    this.http.post(this.subscribeUrl, 
      { notification : sub , student : myStudent, student_id: myStudent.student_id }
      , { headers: this.headers })
      .subscribe();
  }


  send(sub: any) {
    //return this.http.post('/api/newsletter', null);
    return this.http.post(this.sendUrl, null);
    return this.http.post(this.subscribeUrl, sub, { headers: this.headers });
  }

}


