import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-login-thaid',
  templateUrl: './teacher-login-thaid.component.html',
  styleUrls: ['./teacher-login-thaid.component.css']
})
export class TeacherLoginThaidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
