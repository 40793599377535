
  <div class="box">
    <div class="social-auth-links text-center">
      <button i18n="@@subscribe" class="btn btn-default-ace btn-block btn-flat" (click)="subscribeToNotifications()">Subscribe</button>
    </div>

    <!-- <p *ngIf="isGranted" class="success">
      Notifications were granted by the user
    </p> -->
    <p *ngIf="!isEnabled" class="error">
      Notifications are not available in the browser or enabled in the application
    </p>
      {{errorMessage}}
  </div>
