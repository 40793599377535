import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/do';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router:Router){

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))
    if (authInfo != null) {
      const cloned = req.clone({ headers: req.headers.set('x-access-token', authInfo.token) });
      //const cloned = req.clone({ headers: req.headers.set('x-access-token', "123") });
           
      // simple way
      //return next.handle(cloned);


      // Intercept response too
/*       return next.handle(cloned).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403 || err.status === 500) {
            // redirect to the login route
            // or show a modal
            //alert("Token is not valid");
              sessionStorage.setItem(environment.localAuthenInfo, null);      
              this.router.navigate(["/login"]);
          }
        }
      });
 */

      return next.handle(cloned).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        }
          , (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 403 || err.status === 500) {
                // redirect to the login route
                // or show a modal
                //alert("Token is not valid");
                  sessionStorage.setItem(environment.localAuthenInfo, null);      
                  this.router.navigate(["/login"]);
              }
            }
          })
      );


    } else {
      return next.handle(req);
    }
  }
}