import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthenService } from '../../services/authen.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isError = false;
  errorMessage: string;

  constructor(private router: Router,
    private server :AuthenService) { }

  ngOnInit() {
    //if login is called, when already logged in. how to handle?
    let authenInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))
    if (authenInfo !=null && authenInfo.token != "") {
      //this.router.navigate(["student/grades"]);
      this.router.navigate(["student/newsfeed"]);
    }
  }
  
  onSubmit(credential){
    //alert(JSON.stringify(credential));
    this.server.login(credential).subscribe(result=>{
      if (result.auth == true) {
        (<any>window).ga('send', 'event', {
          eventCategory: 'eventCategory',
          eventLabel: 'eventLabel',
          eventAction: 'eventAction',
          eventValue: 10
        });

        this.isError = false;
        //alert('RESULT FROM CREDENTIAL ' + JSON.stringify(result));
        sessionStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
        this.errorMessage = result.msg;
        //this.router.navigate(["student/grades"]);
        this.router.navigate(["student/newsfeed"]);
      } else {
        (<any>window).ga('send', 'event', {
          eventCategory: 'eventCategory',
          eventLabel: 'eventLabel',
          eventAction: 'eventAction',
          eventValue: 0
        });

        this.isError = true;
        console.log("false");
        console.log(JSON.stringify(result));
        this.errorMessage = result.msg;
      }
    },
    error =>{
      if (error.status === 401){
        this.isError = true;
      }
    })
  }

/*   private promptEvent: any;

  openPromptComponent() {
    this.bottomSheet.open(InstallPromptComponent, { data: { mobileType: 'ios', promptEvent: this.promptEvent } });
  } */

}


// import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
// import { InstallPromptComponent } from '../install-prompt/install-prompt.component';



