import { Component, OnInit, Input, ViewChild, TemplateRef, ViewContainerRef, AfterViewInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-progressspinner',
  templateUrl: './progressspinner.component.html',
  styleUrls: ['./progressspinner.component.css']
})
export class ProgressspinnerComponent implements OnInit, AfterViewInit {


  @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<any>;
  
  @Input() color: ThemePalette = 'accent';
  @Input() mode: ProgressSpinnerMode;
  @Input() strokeWidth: number;
  @Input() diameter: number = 100;
  @Input() value: number;
  
  constructor(
    private loadingService: LoadingService,
    private _viewContainerRef: ViewContainerRef) { 
      loadingService.indeterminate.subscribe( //no progress
        _ =>{
          this.mode = 'indeterminate';
        }
      );
      loadingService.determinate.subscribe( //for progress
        value => {
          this.value = value;
          this.mode = 'determinate';
        }
      )

    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadingService.attach(this.templatePortalContent, this._viewContainerRef);
  }
}
