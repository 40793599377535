<ng-template #templatePortalContent>
    <mat-progress-spinner
    class="example-margin"
    [color]="color"
    [mode]="mode"
    [value]="value"
    [strokeWidth]="strokeWidth"
    [diameter]="diameter">
    </mat-progress-spinner>
</ng-template>
