import { Component, Input, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comment, CommentInterface } from '../../models/comment';
import { CommentsService } from '../../services/comments.service';
import { AuthenUserService } from '../../services/authen-user.service';
import { Teacher } from '../../models/student';
import { CommentActiveInterface } from '../../models/commentActive';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  /*   @Input() comment: any;
    showReplyBox = false;
    replyText = '';
  
    submitReply() {
      // Implement your logic here to post a reply.
      // This could involve calling a service method which communicates with the backend.
    } */

  @Input() attendanceGid: string;
  @Input() currentUserId: string;
  @Input() commentGid: string;
  nTeacher: Teacher;
  commentActive: CommentActiveInterface | null = null;
  comments: CommentInterface[] = [];  // Mocked comments list

  showReplyBox = false;
  replyText = '';
  replies = [];  // Mocked replies list

  submitReply() {
    // Push a new reply to our mock replies array
    this.replies.push({ text: this.replyText });
    this.replyText = '';
    this.showReplyBox = false;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private commentService: CommentsService,
    private authenUserService: AuthenUserService) { }

  ngOnInit(): void {
    this.nTeacher = new Teacher();

    this.authenUserService.employee.subscribe(
      employee => {
        this.nTeacher = employee;
        this.currentUserId = this.nTeacher.teacher_id;
      }
    );
    console.log('attendanceGid component', this.data.attendanceGid);
    // this.commentGid = this.data.commentGid;
    this.attendanceGid = this.data.attendanceGid;

    this.commentService.getCommentsByAttendanceGid(this.attendanceGid).subscribe((comments) => {
      this.comments = comments;
      console.log(this.comments);
    });

    console.log('this.nTeacher.teacher_id', this.nTeacher.teacher_id);
  }

  addComment({ text, parentId, }: { text: string; parentId: string | null; }): void {
    const myComment: Comment = {
      COMMENT_TEXT: text,
      PARENT_COMMENT_GID: parentId,
      ATTENDANCE_GID: this.attendanceGid,
      COMMENT_GID: '',
      COMMENTER_USERID: '',
      COMMENTED_DATE: null
    };

    this.commentService
      .createComment(myComment)
      .subscribe((createdComment) => {
        console.log('this.comments before' , this.comments);
        this.comments = [...this.comments, createdComment];
        this.commentActive = null;
        console.log('this.commentActive');
        console.log('createdComment' , createdComment);
        console.log('this.comments' , this.comments);
      });

    console.log('addComment', text, parentId);
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment) => comment.PARENT_COMMENT_GID === null);
  }

  getReplies(commentId: string): CommentInterface[] {
    return this.comments
      .filter((comment) => comment.PARENT_COMMENT_GID === commentId)
      .sort((a, b) => {
        if (a.COMMENTED_DATE instanceof Date && b.COMMENTED_DATE instanceof Date) {
          return a.COMMENTED_DATE.getTime() - b.COMMENTED_DATE.getTime();
        }
        return 0;
      });
  }

  setActiveComment(commentActive: CommentActiveInterface | null): void {
    this.commentActive = commentActive;
    console.log('Emitted value from comment component:', this.commentActive);
  }

  updateComment({
    text,
    commentId,
  }: {
    text: string;
    commentId: string;
  }): void {

    this.commentService
      .updateComment(commentId, text)
      .subscribe((updatedComment) => {
        this.comments = this.comments.map((comment) => {
          if (comment.COMMENT_GID === commentId) {
            console.log('updatedComment', updatedComment);
            return updatedComment;
          }
          console.log('updatedComment comment', comment);
          return comment;
        });

        this.commentActive = null;
      });
  }

  deleteComment(commentId: string): void {
    this.commentService.deleteComment(commentId).subscribe(() => {
      this.comments = this.comments.filter(
        (comment) => comment.COMMENT_GID !== commentId
      );
    });
  }

}
