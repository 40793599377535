<div class="comment">
    <mat-card appearance="outlined" class="comment-card">
        <div class="comment-header">
            <!-- Placeholder for user profile picture 
            <div class="comment-avatar" [style.backgroundImage]="getAvatarStyle(comment?.profileImage)"></div>-->
            <div class="comment-avatar" [style.backgroundImage]="getAvatarStyle('')"></div>

            <div class="comment-meta">
                <div class="comment-author">{{ comment.COMMENTER_USERID }}</div>
                <div class="comment-date">{{ comment.COMMENTED_DATE | date:'medium' }}</div>
            </div>
        </div>
        <div class="comment-content">
            {{ comment.COMMENT_TEXT }}
        </div>

        <app-comment-reply
            *ngIf="isEditing()"
            submitLabel="Update"
            [hasCancelButton]="true"
            [initialText]="comment.COMMENT_TEXT"
            (handleSubmit)="
                updateComment.emit({ text: $event, commentId: comment.COMMENT_GID })
            "
            (handleCancel)="setActiveComment.emit(null)"
            ></app-comment-reply>
        <div class="comment-actions">
            <div *ngIf="canReply" class="comment-action" (click)="handleReplyClick()">
                Reply
            </div>
            <div *ngIf="canEdit" class="comment-action" (click)="
              setActiveComment.emit({
                id: comment.COMMENT_GID,
                type: commentActiveType.editing
              })
            ">
                Edit
            </div>

            <div
                *ngIf="canDelete"
                class="comment-action"
                (click)="deleteComment.emit(comment.COMMENT_GID)"
                >
                Delete
                </div>
            
        </div>
        <app-comment-reply *ngIf="isReplying()" submitLabel="Reply"
            (handleSubmit)="addComment.emit({ text: $event, parentId: replyId })">
        </app-comment-reply>

            <div class="replies" *ngIf="replies.length > 0">
                <app-comment
                  *ngFor="let reply of replies"
                  [comment]="reply"
                  (setActiveComment)="setActiveComment.emit($event)"
                  [activeComment]="activeComment"
                  (deleteComment)="deleteComment.emit($event)"
                  (addComment)="addComment.emit($event)"
                  [parentId]="comment.COMMENT_GID"
                  [replies]="[]"
                  [currentUserId]="currentUserId"
                  (updateComment)="updateComment.emit($event)"
                  (deleteComment)="deleteComment($event)"
                ></app-comment>
            </div>

    </mat-card>
</div>