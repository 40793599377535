import { Injectable, Inject, RendererFactory2, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MsClarityService {

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  injectClarityScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.clarity.ms/tag/${environment.msClarityProjectId}`;
    this.renderer.appendChild(this.document.head, script);

    (window as any).clarity = (window as any).clarity || function () {
      (window as any).clarity.q = (window as any).clarity.q || [];
      (window as any).clarity.q.push(arguments);
    };
  }
}
