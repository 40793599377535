<!-- <div class="reply">
    <div class="reply-header">
        <img class="reply-avatar" src="" alt="Profile Image" />
        <div class="reply-meta">
            <div class="reply-author">{{ reply.COMMENTER_USERID }}</div>
            <div class="reply-date">{{ reply.COMMENTED_DATE | date:'short' }}</div>
        </div>
    </div>

    <div class="reply-content">
        {{ reply.COMMENT_TEXT }}
    </div>

    <div class="reply-input-section">
        <mat-form-field class="reply-input-field">
            <textarea matInput [(ngModel)]="replyText" placeholder="Write a reply..."></textarea>
        </mat-form-field>
        <button mat-flat-button color="primary" class="post-reply-btn">Post</button>
    </div>
</div> -->


<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <textarea class="comment-form-textarea" formControlName="title"></textarea>
  <button class="comment-form-button" type="submit" [disabled]="form.invalid">
    {{ submitLabel }}
  </button>
  <button *ngIf="hasCancelButton" type="button" class="comment-form-button comment-form-cancel-button"
    (click)="handleCancel.emit()">
    Cancel
  </button>
</form>