import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Comment, CommentInterface } from '../models/comment';
import { Observable } from 'rxjs';
import { base64UrlEncode } from '../utils/base64-util';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  // private baseUrl = `https://www.checkgrade.net/sjt/`;  // don't use local in case of cross domain or ip address
  // private baseUrl = `https://www.checkgrade.net/stw/`;  // don't use local in case of cross domain or ip address
  private baseUrl = environment.node_static_url;

  private apiUrl = `${this.baseUrl}/api/v1`;
  private commentsUrl = `${this.apiUrl}/comments`;

  constructor(private http: HttpClient) { }

  getCommments(): Observable<CommentInterface[]> {
    return this.http.get<CommentInterface[]>(this.commentsUrl);
  }

  getCommentsByCommentGid(comment_gid: string): Observable<CommentInterface[]> {
    console.log('comment_gid', comment_gid);
    const comment_gid_enconded = base64UrlEncode(comment_gid);

    console.log('comment_gid_enconded', comment_gid_enconded);

    const url = `${this.commentsUrl}/${comment_gid_enconded}`;
    return this.http.get<CommentInterface[]>(url);
  }

  getCommentsByAttendanceGid(attendance_gid: string): Observable<CommentInterface[]> {
    const attendance_gid_enconded = base64UrlEncode(attendance_gid);

    const url = `${this.commentsUrl}/attendance/${attendance_gid_enconded}`;
    return this.http.get<CommentInterface[]>(url);
  }

  createComment(myComment: Comment): Observable<CommentInterface> {
    return this.http.post<CommentInterface>(
      `${this.commentsUrl}/attendance`,
      myComment
    );
  }

  updateComment(comment_gid: string, text: string): Observable<CommentInterface> {
    const comment_gid_enconded = base64UrlEncode(comment_gid);

    return this.http.patch<CommentInterface>(
      `${this.commentsUrl}/attendance/comment/${comment_gid_enconded}`, {
      COMMENT_TEXT: text
    },
      { headers: this.headers }
    );
  }

  deleteComment(comment_gid: string): Observable<{}> {
    const comment_gid_enconded = base64UrlEncode(comment_gid);

    return this.http.delete(`${this.commentsUrl}/attendance/comment/${comment_gid_enconded}`);
  }


}



