import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

//import {DomSanitizer} from '@angular/platform-browser';
//import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;
  //constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) { 
  constructor() { 

  }

  ngOnInit() {
  }


}