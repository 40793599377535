import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Employee } from '../models/student';

@Injectable({
  providedIn: 'root'
})
export class AuthenUserService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private baseUrl = environment.node_static_url;

  private apiUrl = `${this.baseUrl}/api/v1`;
  private loginUrl = `${this.apiUrl}/user/login`;
  private logoutUrl = `${this.apiUrl}/user/logout`;
  private changePasswordUrl = `${this.apiUrl}/user/changepw`;

  private employeeSubject: BehaviorSubject<Employee>;
  public employee: Observable<Employee>;


  //DI
  constructor(private http: HttpClient) {
      try {
        JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
        this.employeeSubject = new BehaviorSubject<Employee>(
          JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))
        );
        
      } catch (error) {
        console.log('error: '  + error);
        console.error(error);
        this.setAuthenticated(false);
        location.reload();

      }
      this.employee = this.employeeSubject.asObservable();
    
  }

  login(credential): Observable<any> {
    this.storePassword(credential.username, credential.password);
    return this.http
      .post<any>(this.loginUrl, credential, { headers: this.headers })
       .pipe(
        tap((result) => {
          console.log('RESULT FROM LOGIN: ' + JSON.stringify(result));
          sessionStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
          this.employeeSubject.next(result);
        }
        )
      ); 

  }

  //logout():Observable<void>{
  //  return this.http.get<void>(this.logoutUrl,  {headers: this.headers})
  //}

  isAuthenticated(): boolean {
    try {
      let authenInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
      //alert('Please login' + authenInfo.token);
      if (authenInfo.token != "") {
        this.loggedIn = true;
        return true;
      }
    } catch (error) {
      this.loggedIn = false;
      return false;
    }
    this.loggedIn = false;
    return false;
    // Check whether the token is expired and return
    // true or false
  }


  setAuthenticated(value: boolean) {
    if(value){

    } else {

    }
    sessionStorage.removeItem(environment.localAuthenInfo);
  }

  private async storePassword(username: string, password: string): Promise<any> {
    if (!(window as any).PasswordCredential) {
      return Promise.resolve();
    }

    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username
    });
    return (navigator as any).credentials.store(cred);
  }

  private loggedIn = false;
  logout(): Observable<void> {
    return this.http
      .get<void>(this.logoutUrl, { headers: this.headers })
      .pipe(tap(() => {
        this.loggedIn = false;
        this.setAuthenticated(false);
        this.employeeSubject.next(null);
      }))
  }

  public get employeeValue(): Employee {
    return this.employeeSubject.value
  }

  changePassword(credential): Observable<any> {
    //this.storePassword(credential.username, credential.password);
    return this.http
      .post<any>(this.changePasswordUrl, credential, { headers: this.headers })
       .pipe(
        tap((result) => {
          console.log('RESULT FROM CHANGE PASSWORD: ' + JSON.stringify(result));
          //sessionStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
          //this.employeeSubject.next(result);
        }
        )
      ); 

  }

}

