<footer class="main-footer">
  <div class="d-lg-none">
    <nav class="navbar navbar-expand navbar-ace navbar-ace d-flex justify-content-around p-0">
      <ul class="navbar-nav  p-0">

        <li class="nav-item">

          <!--  <button mat-icon-button aria-label="Home Nav" class="nav-footer-button">
            <mat-icon svgIcon="home" aria-hidden="false" aria-label="Home Icon"></mat-icon>
          </button>
-->

        <li class="nav-item">
          <a routerLink="/student/newsfeed" class="nav-link-btm">
            <div class="img-size-32">
              <img src="../../../assets/img/nav_bar_btm_home2x.png" class="img-size-32">
            </div>
            <div class="hidden-s">Home</div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/student/grades" class="nav-link-btm">
            <div class="img-size-32">
              <img src="../../../assets/img/nav_bar_btm_grade2x.png" class="img-size-32">
            </div>
            <div class="hidden-s">Grade</div>
          </a>
        </li>
    <!--    <li class="nav-item">
          <a routerLink="/student/evaluation" class="nav-link-btm">
            <div class="img-size-32">
              <img src="../../../assets/img/nav_bar_btm_evaluation2x.png" class="img-size-32">
            </div>
            <div class="hidden-s">Evaluation</div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/student/payment" class="nav-link-btm">
            <div class="img-size-32">
              <img src="../../../assets/img/nav_bar_btm_payment2x.png" class="img-size-32">
            </div>
            <div class="hidden-s">Payment</div>
          </a>
        </li> -->
        <li class="nav-item">
          <a routerLink="/student/attendance" class="nav-link-btm">
            <div class="img-size-32">
              <img src="../../../assets/img/nav_bar_btm_att2x.png" class="img-size-32">
            </div>
            <div class="hidden-s">Attendance</div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="d-none d-lg-block">
    <strong>Copyright &copy; 2020 <a href="https://www.infopro.co.th">Infopro Business Solution Co.,Ltd</a>.</strong>
    All
    rights
    reserved. Version:
    {{currentApplicationVersion}}
  </div>
</footer>