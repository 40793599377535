import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from '../components/comment/comment.component';
import { CommentReplyComponent } from '../components/comment-reply/comment-reply.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicAnchorDirective } from './directives/dynamic-anchor.directive';
import { NotificationComponent } from '../components/notification/notification.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CommentComponent,
    CommentReplyComponent,
    DynamicAnchorDirective,
    NotificationComponent,],
  exports: [CommentComponent,
    CommentReplyComponent,
    NotificationComponent,],
})
export class SharedModule { }
