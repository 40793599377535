import { Component, Input, Inject, EventEmitter, Output, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentInterface } from '../../models/comment';
import { CommentsService } from '../../services/comments.service';
import { CommentActiveInterface } from '../../models/commentActive';
import { CommentActiveTypeEnum } from '../../models/commentActiveType.enum'; // Import the missing enum

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() comment!: CommentInterface;
  @Input() commentActive!: CommentActiveInterface | null;
  @Input() currentUserId!: string;
  @Input() replies: CommentInterface[];
  @Input() parentId!: string | null;

  @Output()
  setActiveComment = new EventEmitter<CommentActiveInterface | null>();
  @Output()
  addComment = new EventEmitter<{ text: string; parentId: string | null }>();
  @Output()
  updateComment = new EventEmitter<{ text: string; commentId: string }>();
  @Output()
  deleteComment = new EventEmitter<string>();

  createdAt: string = '';
  canReply: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  commentActiveType = CommentActiveTypeEnum;
  replyId: string | null = null;

  getAvatarStyle(imageUrl: string | undefined): string {
    return imageUrl ? `url(${imageUrl})` : 'none';
  }

  ngOnInit(): void {
    console.log('currentUserId', this.currentUserId);

    const fiveMinutes = 300000;
    const timePassed =
      new Date().getMilliseconds() -
      new Date(this.comment.COMMENTED_DATE).getMilliseconds() >
      fiveMinutes;
    this.canReply = Boolean(this.currentUserId) && !this.parentId;
    //this.canEdit = this.currentUserId === this.comment.COMMENTER_USERID && !timePassed;
    this.canEdit = false;
    this.canDelete =
      this.currentUserId === this.comment.COMMENTER_USERID &&
      this.replies.length === 0 &&
      !timePassed;

    this.replyId = this.parentId ? this.parentId : this.comment.COMMENT_GID;
  }

  isReplying(): boolean {
    //console.log('this.commentActive', this.commentActive);
    if (!this.commentActive) {
      //console.log('!this.commentActive');
      return false;
    }

    return (
      this.commentActive.id === this.comment.COMMENT_GID &&
      this.commentActive.type === this.commentActiveType.replying
    );
  }

  isEditing(): boolean {
    if (!this.commentActive) {
      return false;
    }
    return (
      this.commentActive.id === this.comment.COMMENT_GID &&
      this.commentActive.type === this.commentActiveType.editing
    );
  }

  handleReplyClick(): void {
    console.log('handleReplyClick');
    this.setActiveComment.emit({
      id: this.comment.COMMENT_GID,
      type: this.commentActiveType.replying
    });
  }

}
