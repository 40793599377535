import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/models/menu';
import { Platform } from '@angular/cdk/platform';
import { PwaInstallService } from '../../services/pwa-install.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
  // //ALL
  // nMenuStudent: Menu[] = [
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 1, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper-o"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 2, MENU_NAME: 'Subjects', OPTION_NAME: 'Subjects', OPTION_OBJECT: "/student/subjects", ICON: "nav-icon fas fa-tachometer-alt"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 3, MENU_NAME: 'Check-in', OPTION_NAME: 'Check-in', OPTION_OBJECT: "/student/checkin", ICON: "nav-icon fa fa-clock-o"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 4, MENU_NAME: 'Capture Image', OPTION_NAME: 'Capture Image', OPTION_OBJECT: "/student/capture", ICON: "nav-icon fa fa-camera"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 5, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star-o"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 6, MENU_NAME: 'Evaluation', OPTION_NAME: 'Evaluation', OPTION_OBJECT: "/student/evaluation", ICON: "nav-icon fa fa-pencil-square-o"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 7, MENU_NAME: 'Payment', OPTION_NAME: 'Payment', OPTION_OBJECT: "/student/payment", ICON: "nav-icon fa fa-money"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 8, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 9, MENU_NAME: 'Card Balance', OPTION_NAME: 'Card Balance', OPTION_OBJECT: "/student/wallet", ICON: "nav-icon fa fa-credit-card"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/student/settings", ICON: "nav-icon fa fa-gear"},
  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 11, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
  // ];

  // STR
  //  nMenuStudent: Menu[] = [
  //  {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 20, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 40, MENU_NAME: 'Evaluation', OPTION_NAME: 'Evaluation', OPTION_OBJECT: "/student/evaluation", ICON: "nav-icon fa fa-pencil-alt"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 50, MENU_NAME: 'Payment', OPTION_NAME: 'Payment', OPTION_OBJECT: "/student/payment", ICON: "nav-icon fa fa-money-bill"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 60, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 70, MENU_NAME: 'Card Balance', OPTION_NAME: 'Card Balance', OPTION_OBJECT: "/student/wallet", ICON: "nav-icon fa fa-credit-card"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/student/settings", ICON: "nav-icon fa fa-tools"},
  //];
  //  nMenuStudent: Menu[] = [
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
  //    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},

  //  {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 110, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/teacher/newsfeed", ICON: "nav-icon fa fa-newspaper"},
  //  {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 120, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/teacher/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
  //  ];


  //  STFX
  /*    nMenuStudent: Menu[] = [
     {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
       {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 20, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
       {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},
       {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 60, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},
       {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/student/settings", ICON: "nav-icon fa fa-tools"},
      ]; */


  //  PH
  /* nMenuStudent: Menu[] = [
    {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
      {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 20, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
      {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},
      {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 50, MENU_NAME: 'Payment', OPTION_NAME: 'Payment', OPTION_OBJECT: "/student/payment", ICON: "nav-icon fa fa-money-bill"},
      {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 60, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},
      {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/student/settings", ICON: "nav-icon fa fa-tools"},
     ];*/

  // TEACHER

  //  DVP
  /* nMenuStudent: Menu[] = [
    {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 120, MENU_NAME: 'Clock-in Clock-out RFID', OPTION_NAME: 'Clock-in Clock-out RFID', OPTION_OBJECT: "/rfid/clockin", ICON: "nav-icon fa fa-user-clock"},
            ];*/


  //     //DARA
  //  nMenuStudent: Menu[] = [
  //  // {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
  //  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 20, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
  //  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},
  //  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 50, MENU_NAME: 'Payment', OPTION_NAME: 'Payment', OPTION_OBJECT: "/student/payment", ICON: "nav-icon fa fa-money-bill"},
  //  //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 60, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},


  // //    {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 120, MENU_NAME: 'Newsfeed', OPTION_NAME: 'Newsfeed', OPTION_OBJECT: "/teacher/newsfeed", ICON: "nav-icon fa fa-newspaper"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 130, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/teacher/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 141, MENU_NAME: 'Clock-in Clock-out', OPTION_NAME: 'Clock-in Clock-out', OPTION_OBJECT: "/teacher/clockin", ICON: "nav-icon fa fa-user-clock"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 120, MENU_NAME: 'Clock-in Clock-out RFID', OPTION_NAME: 'Clock-in Clock-out RFID', OPTION_OBJECT: "/teacher/rfid/clockin", ICON: "nav-icon fa fa-user-clock"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 109, MENU_NAME: 'Subjects Master', OPTION_NAME: 'Subjects Master', OPTION_OBJECT: "/teacher/subj", ICON: "nav-icon fa fa-star"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 110, MENU_NAME: 'Subjects', OPTION_NAME: 'Subjects', OPTION_OBJECT: "/teacher/subjects", ICON: "nav-icon fa fa-star"},
  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 111, MENU_NAME: 'Classes', OPTION_NAME: 'Classes', OPTION_OBJECT: "/teacher/classes", ICON: "nav-icon fa fa-star"},
  // //    {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 110, MENU_NAME: 'Sessions', OPTION_NAME: 'Sessions', OPTION_OBJECT: "/teacher/sessions", ICON: "nav-icon fa fa-star"},

  //     {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 140, MENU_NAME: 'Fixed Asset Responsible', OPTION_NAME: 'ครุภัณฑ์ที่รับผิดชอบ', OPTION_OBJECT: "/fa/employee/responsible", ICON: "nav-icon fa fa-boxes"},
  //     {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/student/settings", ICON: "nav-icon fa fa-tools"},
  //    ];

  //AUA
  // nMenuStudent: Menu[] = [
  //   { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 130, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/teacher/notifications", ICON: "nav-icon fas fa-tachometer-alt" },
  //   { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 110, MENU_NAME: 'Sessions', OPTION_NAME: 'Sessions', OPTION_OBJECT: "/teacher/sessions", ICON: "nav-icon fa fa-star" },
  //   { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/teacher/settings", ICON: "nav-icon fa fa-tools" },
  // ];

  nMenuStudent: Menu[] = [
    // {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 10, MENU_NAME: 'News Feed', OPTION_NAME: 'News Feed', OPTION_OBJECT: "/student/newsfeed", ICON: "nav-icon fa fa-newspaper"},
    //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 20, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/student/notifications", ICON: "nav-icon fas fa-tachometer-alt"},
    //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 30, MENU_NAME: 'Grades', OPTION_NAME: 'Grades', OPTION_OBJECT: "/student/grades", ICON: "nav-icon fa fa-star"},
    //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 50, MENU_NAME: 'Payment', OPTION_NAME: 'Payment', OPTION_OBJECT: "/student/payment", ICON: "nav-icon fa fa-money-bill"},
    //   {MODULE_CODE: "ACE_STU", MODULE_LINE_NO: 60, MENU_NAME: 'Attendance', OPTION_NAME: 'Attendance', OPTION_OBJECT: "/student/attendance", ICON: "nav-icon fa fa-calendar"},


    //    {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 120, MENU_NAME: 'Newsfeed', OPTION_NAME: 'Newsfeed', OPTION_OBJECT: "/teacher/newsfeed", ICON: "nav-icon fa fa-newspaper"},
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 130, MENU_NAME: 'Notifications', OPTION_NAME: 'Notifications', OPTION_OBJECT: "/teacher/notifications", ICON: "nav-icon fas fa-tachometer-alt" },
    // {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 141, MENU_NAME: 'Clock-in Clock-out', OPTION_NAME: 'Clock-in Clock-out', OPTION_OBJECT: "/teacher/clockin", ICON: "nav-icon fa fa-user-clock"},
    //  {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 120, MENU_NAME: 'Clock-in Clock-out RFID', OPTION_NAME: 'Clock-in Clock-out RFID', OPTION_OBJECT: "/teacher/rfid/clockin", ICON: "nav-icon fa fa-user-clock"},
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 109, MENU_NAME: 'Subjects Master', OPTION_NAME: 'Subjects Master', OPTION_OBJECT: "/teacher/subj", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 110, MENU_NAME: 'Subjects', OPTION_NAME: 'Subjects', OPTION_OBJECT: "/teacher/subjects", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 111, MENU_NAME: 'Classes', OPTION_NAME: 'Classes', OPTION_OBJECT: "/teacher/classes", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 111, MENU_NAME: 'Learning Unit', OPTION_NAME: 'Learning Unit', OPTION_OBJECT: "/teacher/lunits", ICON: "nav-icon fa fa-star" },

    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 110, MENU_NAME: 'Sessions', OPTION_NAME: 'Sessions', OPTION_OBJECT: "/teacher/sessions", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "SV", MODULE_LINE_NO: 120, MENU_NAME: 'Service Incidents', OPTION_NAME: 'Service Incidents', OPTION_OBJECT: "/sv/incidents", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "SV", MODULE_LINE_NO: 130, MENU_NAME: 'Service Change Requests', OPTION_NAME: 'Service Change Requests', OPTION_OBJECT: "/sv/change/requests", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "SV", MODULE_LINE_NO: 140, MENU_NAME: 'Service Test Record', OPTION_NAME: 'Service Test Record', OPTION_OBJECT: "/sv/change/tests", ICON: "nav-icon fa fa-star" },

    { MODULE_CODE: "UM", MODULE_LINE_NO: 150, MENU_NAME: 'UM Reg Verify', OPTION_NAME: 'UM Reg Verify', OPTION_OBJECT: "/um/reg/heads", ICON: "nav-icon fa fa-star" },

    { MODULE_CODE: "CMS", MODULE_LINE_NO: 160, MENU_NAME: 'CMS Registration', OPTION_NAME: 'CMS Registration', OPTION_OBJECT: "/cms/es/articles", ICON: "nav-icon fa fa-star" },

    { MODULE_CODE: "BG", MODULE_LINE_NO: 170, MENU_NAME: 'ใบกันงบ', OPTION_NAME: 'ใบกันงบ', OPTION_OBJECT: "/bg/ba", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "HRI", MODULE_LINE_NO: 180, MENU_NAME: 'ใบลา', OPTION_NAME: 'ใบลา', OPTION_OBJECT: "/hri/le", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "BGEST", MODULE_LINE_NO: 190, MENU_NAME: 'จัดสรรงบประมาณ', OPTION_NAME: 'จัดสรรงบประมาณ', OPTION_OBJECT: "/bgest/est", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "JOB", MODULE_LINE_NO: 200, MENU_NAME: 'มิติ3', OPTION_NAME: 'มิติ3', OPTION_OBJECT: "/job/jobs", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "RF", MODULE_LINE_NO: 210, MENU_NAME: 'ใบขอเบิกค่าใช้จ่าย', OPTION_NAME: 'ใบขอเบิกค่าใช้จ่าย', OPTION_OBJECT: "/rf/exp", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "PR2", MODULE_LINE_NO: 220, MENU_NAME: 'แบบคำขอจัดหาพัสดุ', OPTION_NAME: 'แบบคำขอจัดหาพัสดุ', OPTION_OBJECT: "/pr/prs", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 230, MENU_NAME: 'ประวัติบุคลากร', OPTION_NAME: 'ประวัติบุคลากร', OPTION_OBJECT: "/hr_emp/emp", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 231, MENU_NAME: 'ประวัติการศึกษา', OPTION_NAME: 'ประวัติการศึกษา', OPTION_OBJECT: "/hr_emp/educ", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 232, MENU_NAME: 'เครื่องราช', OPTION_NAME: 'เครื่องราช', OPTION_OBJECT: "/hr_emp/rank", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "AE", MODULE_LINE_NO: 240, MENU_NAME: 'ใบยืมเงินทดรอง', OPTION_NAME: 'ใบยืมเงินทดรอง', OPTION_OBJECT: "/ae/ar", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "VC_PRE", MODULE_LINE_NO: 250, MENU_NAME: 'ใบคืนเงินยืมทดรอง', OPTION_NAME: 'ใบคืนเงินยืมทดรอง', OPTION_OBJECT: "/vc_pre/pre", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "RQ", MODULE_LINE_NO: 260, MENU_NAME: 'ใบเบิกวัสดุ', OPTION_NAME: 'ใบเบิกวัสดุ', OPTION_OBJECT: "/rq/rqs", ICON: "nav-icon fa fa-star" },
    // { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 232, MENU_NAME: 'เครื่องราช', OPTION_NAME: 'เครื่องราช', OPTION_OBJECT: "/hr_emp/rank", ICON: "nav-icon fa fa-star" },
    // { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 232, MENU_NAME: 'เครื่องราช', OPTION_NAME: 'เครื่องราช', OPTION_OBJECT: "/hr_emp/rank", ICON: "nav-icon fa fa-star" },
    { MODULE_CODE: "IF", MODULE_LINE_NO: 300, MENU_NAME: 'ThaID', OPTION_NAME: 'ThaID', OPTION_OBJECT: "/if/home", ICON: "nav-icon fa fa-star" },
    //  { MODULE_CODE: "PO_WR", MODULE_LINE_NO: 250, MENU_NAME: 'รับหลักประกัน', OPTION_NAME: 'รับหลักประกัน', OPTION_OBJECT: "/po_wr/wr", ICON: "nav-icon fa fa-star" },
    //  { MODULE_CODE: "HR_EMP", MODULE_LINE_NO: 260, MENU_NAME: 'ใบสำคัญจ่าย', OPTION_NAME: 'ใบสำคัญจ่าย', OPTION_OBJECT: "/hr_emp/emp", ICON: "nav-icon fa fa-star" },

    { MODULE_CODE: "LW", MODULE_LINE_NO: 222, MENU_NAME: 'Livewell', OPTION_NAME: 'Livewell', OPTION_OBJECT: "/lw/lws", ICON: "nav-icon fa fa-star" },

    //  {MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 140, MENU_NAME: 'Fixed Asset Responsible', OPTION_NAME: 'ครุภัณฑ์ที่รับผิดชอบ', OPTION_OBJECT: "/fa/employee/responsible", ICON: "nav-icon fa fa-boxes"},
    { MODULE_CODE: "ACE_TEA", MODULE_LINE_NO: 80, MENU_NAME: 'Settings', OPTION_NAME: 'Settings', OPTION_OBJECT: "/teacher/settings", ICON: "nav-icon fa fa-tools" },
  ];


  deferredPrompt;

  constructor(private platform: Platform,
    private pwaInstallService: PwaInstallService) {

    window.addEventListener('beforeinstallprompt', (event) => {
      //// Prevent the mini-infobar from appearing on mobile
      event.preventDefault();
      //// Stash the event so it can be triggered later.
      this.deferredPrompt = event;
      //// Update UI notify the user they can install the PWA
      //showInstallPromotion();
    });


    //this.deferredPrompt = this.pwaInstallService.promptEvent;
  }

  ngOnInit() {

  }

  //showInstallPromotion();

  installPwa(): void {
    //// Hide the app provided install promotion
    //hideMyInstallPromotion();
    //// Show the install prompt
    this.deferredPrompt.prompt();
  }

}
