import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize, delay } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../environments/environment';

@Injectable()
export class WebserviceInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, private loadingService: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();

    if(!req.reportProgress){
      this.loadingService.indeterminate.next(true);
    }

    return next.handle(req).pipe(
      tap(
        event => {
          switch (event.type) {
            case HttpEventType.UploadProgress: //for detect determinate progress
              const progress = Math.round(100 * event.loaded / event.total);
              console.log(progress);
              this.loadingService.determinate.next(progress);
              break;

            case HttpEventType.Response:
              if (event instanceof HttpResponse) {
                const config: MatSnackBarConfig = {
                  duration: 4000,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['snackbar', 'snackbar-green']
                }

                switch (event.status) {
                  case 200:
                    if (req.method === 'PUT') {
                      this.snackBar.open("Edited Successfully", null, config);
                    } else {
                      if (environment.production) {
                        //
                      } else {
                        this.snackBar.open("Selected Successfully", null, config);
                      }
                        
                    }
                    break;
                  case 201:
                    this.snackBar.open("Created Successfully", null, config);
                    break;
                  case 204:
                    this.snackBar.open("Deleted Successfully", null, config);
                    break;
                }
                break;
              }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            const config: MatSnackBarConfig = {
              duration: 4000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ['snackbar', 'snackbar-error']
            }
            // console.log("ERROR.status : " + error.status);
            // console.log("ERROR.message : " + error.message);
            // console.log("ERROR : " + error.error.message);
            if (error.status === 401 || error.status === 403) {
              this.snackBar.open("Unauthen", null, config)
            } else if (error.status === 404 && !error.error.message) {
              this.snackBar.open(error.error.message, null, config)
            } else if (error.status === 500) {
              //this.snackBar.open("Feature not enabled: " + error.message , null, config)
              //this.snackBar.open("Feature not enabled" , null, config);
              this.snackBar.open(error.error.message, null, config)
            } else {
              this.snackBar.open(error.message, null, config)
            }
          }
        }
      ), ///      delay(5000), //if we want to show delay
      finalize(() => {
        //this.loadingService.hidden();
        this.loadingService.indeterminate.next(false);
        //this.loadingService.determinate.next(100);
      })
    );
  }
}
