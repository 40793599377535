import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenUserService } from '../../services/authen-user.service';
import { environment } from '../../../environments/environment';
import { timingSafeEqual } from 'crypto';
import { GoogleAnalyticsService} from '../../services/google-analytics.service';

@Component({
  selector: 'app-login-teacher',
  templateUrl: './login-teacher.component.html',
  styleUrls: ['./login-teacher.component.css']
})
export class LoginTeacherComponent implements OnInit {
  isError = false;
  returnUrl: string;
  defaultUrl: string = environment.defaultUrl;

  constructor(private router: Router,
    private server :AuthenUserService,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    //if login is called, when already logged in. how to handle?
    let authenInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.defaultUrl;
    //console.log("returnUrl" , this.returnUrl);

    if (authenInfo !=null && authenInfo.token != "") {
      this.router.navigateByUrl(this.returnUrl);
    }
  }
  
  onSubmit(credential){
    //alert(JSON.stringify(credential));
    this.server.login(credential).subscribe(result=>{
      if (result.auth == true) {
        this.googleAnalyticsService.eventEmitter('loginCategory', 'loginLabel', 'successLoginAction', 10);
        // (<any>window).ga('send', 'event', {
        //   eventCategory: 'loginCategory',
        //   eventLabel: 'loginLabel',
        //   eventAction: 'successLoginAction',
        //   eventValue: 10
        // });

        this.isError = false;
        //alert('RESULT FROM CREDENTIAL ' + JSON.stringify(result));
        //sessionStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));

        //*** no need to redirect because interceptor already navigate */
        //this.router.navigate([this.returnUrl]);

        //this.router.navigateByUrl(this.returnUrl);
      } else {
        this.googleAnalyticsService.eventEmitter('loginCategory', 'loginLabel', 'failedLoginAction', 0);

        // (<any>window).ga('send', 'event', {
        //   eventCategory: 'loginCategory',
        //   eventLabel: 'loginLabel',
        //   eventAction: 'failedLoginAction',
        //   eventValue: 0
        // });

        this.isError = true;

      }
    },
    error =>{
      if (error.status === 401){
        this.isError = true;

      }
    })
  }

/*   private promptEvent: any;

  openPromptComponent() {
    this.bottomSheet.open(InstallPromptComponent, { data: { mobileType: 'ios', promptEvent: this.promptEvent } });
  } */

}


// import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
// import { InstallPromptComponent } from '../install-prompt/install-prompt.component';





