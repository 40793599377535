<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
            <!--<a href="../../index.html"><b>Infopro</b></a> <br>-->
            <img src="../../../assets/img/logo1x.png"> <br>
            <!-- Management -->
            Teacher

          </div>
  
      <form #loginForm="ngForm" (submit)="onSubmit(loginForm.value)">
  
        <div class="form-group has-feedback">
          <input ngModel name="username" autocomplete="username" type="email" class="form-control" placeholder="Username">
        </div>
        <div class="form-group has-feedback">
          <input ngModel name="password" autocomplete="password" type="password" class="form-control"
            placeholder="Password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
  
        <div *ngIf="isError" class="alert alert-danger" role="alert">
          Incorrect username or password!
        </div>
  
        <div class="form-group has-feedback">
          <div class="icheck-primary  d-inline">
            <input type="checkbox" id="remember" checked="" class="form-control">
            <label for="remember">
              Remember Me
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button i18n="@@login" type="submit" class="btn btn-primary btn-block">LOG IN</button>
          </div>
          <!-- /.col -->
        </div>
      </form>
      <br>
      <a href="https://www.infopro.co.th">Powered by Infopro x CICD</a> <br>
      <!--
            <div class="social-auth-links text-center">
              <p>- OR -</p>
              <a href="#" class="btn btn-block btn-social btn-facebook btn-flat"><i class="fa fa-facebook"></i> Sign in
                using
                Facebook</a>
              <a href="#" class="btn btn-block btn-social btn-google btn-flat"><i class="fa fa-google-plus"></i> Sign in
                using
                Google+</a>
            </div>
             /.social-auth-links 
      <p class="mb-1 mt-3 text-center">
        <a href="#">Forget your password?</a>
      </p>-->
      <br>
      <br>
      <!--   <p class="mb-1 mt-3 text-center">
              <a href="#">Don't have an account? <b>Register.</b></a>
            </p>
        <div class="social-auth-links text-center mb-3">
          <p>‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ OR ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧</p>
          <a href="#" class="btn btn-block">
            <i class="fab fa-facebook mr-2"></i> Sign Up With Facebook
          </a>
        </div>-->
  
  
    </div>
  
    <!-- /.login-box-body -->
  
    <!-- /.login-box -->
  </div>