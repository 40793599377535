import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';
import { Student } from 'src/app/models/student';
import { AuthenUserService } from '../../services/authen-user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  node_static_url: String;
  nStudent: Student;
  constructor(private router: Router,
    private authenUserService: AuthenUserService) { }

  ngOnInit() {
    //alert(environment.node_static_url);
    this.node_static_url = environment.node_static_url;

    this.nStudent= new Student();
    
    //this.nStudent.student_id= "60531";
    //alert(JSON.stringify(this.nStudent));
    /* let authInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
    this.nStudent.student_id= authInfo.student_id;
    this.nStudent.first_name= authInfo.first_name;
    this.nStudent.last_name= authInfo.last_name;
    this.nStudent.image_path = authInfo.image_path; */

    this.authenUserService.employee.subscribe(
      employee => this.nStudent = employee
    );

    //this.nStudent.image_path = this.studentsServer.getStudentsImgByStudentId(this.nStudent.student_id);
    //Create Student Image file
    //JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))

  }

  onClickLogout(){
   // console.log('Clicked Logout');
    this.authenUserService.logout().subscribe(result=>{
   //   console.log('Clicked Logout subscribed');
   //   console.log(JSON.stringify(result));
   //   this.server.setAuthenticated(false);
      this.router.navigate(["/login"]);
    })
  }

}
