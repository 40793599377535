import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { WebnotificationService } from '../../services/webnotification.service';

import { Student } from '../../models/student';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent{
  isEnabled = this.swPush.isEnabled;

  //isGranted = Notification.permission === 'granted'; //Notification causes error in iOs and explorer
  nStudent: Student;
  errorMessage: string;
  
    constructor(
        private swPush: SwPush,
        private webnotificationService: WebnotificationService) {}

    subscribeToNotifications() {
      //for current student_id
      this.nStudent = new Student();
      let authInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo));
      if (authInfo == null) {
        this.errorMessage = 'Subscribing for student NULL';
      } else {
        this.nStudent.student_id = authInfo.student_id;
        this.nStudent.first_name = authInfo.first_name;
        this.nStudent.last_name = authInfo.last_name;
        this.errorMessage = 'Subscribing for student ' + this.nStudent.student_id;
       
      //  this.webnotificationService.subscribeToNotification();
        this.webnotificationService.subscribeToNotificationForStudent(this.nStudent);
  
      }
    }
}
