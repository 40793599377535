  <mat-nav-list *ngIf="data.mobileType === 'android'">
    <button mat-icon-button (click)="close()" class="float-right">
      <mat-icon>close</mat-icon>
    </button>
    <p class="mb-3 mt-3 text-center">
      Access your student's activities using our app!
    </p>
    <button i18n="@@installapp" (click)="installPwa()" class="btn btn-primary btn-block">Install App</button>
  </mat-nav-list>

  <mat-nav-list *ngIf="data.mobileType === 'ios'">
    <button mat-icon-button (click)="close()" class="float-right">
      <mat-icon>close</mat-icon>
    </button>
    <p class="mb-3 mt-3 text-center">
      To install this app, tap the Menu button and then 'Add to Home Screen' button
    </p>
    <div><img src="assets/icons/ios-menu-btn.png"> <mat-icon>navigate_next</mat-icon> Add to Home Screen<img src="assets/icons/ios-add-btn.png"></div>
  </mat-nav-list>