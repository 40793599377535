class User {
    first_name: string;
    last_name: string;
    image_path: string;

    constructor() {
        this.first_name = "";
        this.last_name = "";
        this.image_path ="";
    }

}

export class Student extends User {
    student_id: string;
    constructor() {
        super();
        this.student_id = "";
    }
}

export class Teacher extends User {
    teacher_id: string;
    constructor() {
        super();
        this.teacher_id = "";
    }
}

export class Employee extends User {
    teacher_id: string;
    employee_code: string;
    token?: string;
    student_id: string;
    position_id: string;
    constructor() {
        super();
        this.teacher_id = "";
        this.employee_code = "";
        this.student_id = "";
        this.token="";
        this.position_id = "";
    }
}

