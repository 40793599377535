<div class="comments-container">
  <app-comment *ngFor="let rootComment of getRootComments()" [comment]="rootComment" [currentUserId]="currentUserId"
    [replies]="getReplies(rootComment.COMMENT_GID)" [commentActive]="commentActive"
    (setActiveComment)="setActiveComment($event)" (addComment)="addComment($event)"
    (updateComment)="updateComment($event)" (deleteComment)="deleteComment($event)">

  </app-comment>
</div>

<h3 class="comments-title">Comments</h3>
<div class="comment-form-title">Write comment</div>
<app-comment-reply submitLabel="Write" (handleSubmit)="addComment({text: $event, parentId: null})">
</app-comment-reply>