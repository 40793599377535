<app-header *ngIf="mIsAuthen == true"></app-header>
<app-menu *ngIf="mIsAuthen == true"></app-menu>

<div *ngIf="mIsAuthen == true; else elseBlock" class="content-wrapper">
    <app-progressspinner></app-progressspinner>
    <router-outlet></router-outlet>
</div>
<ng-template #elseBlock>
    <app-progressspinner></app-progressspinner>
    <router-outlet></router-outlet>
</ng-template>

<!-- <app-footer *ngIf="mIsAuthen == true"></app-footer> -->