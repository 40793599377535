export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  localUser: "localUser",
  localAuthenInfo: "localAuthenInfo",
  template: "DARA",

  loginUrl: `if/home`,

  localOauthProfile: "localOauthProfile",
  helpManualUrl: "https://idemo.or.th/page/listview/18/200",

  node_static_url: "https://web.infopro.app/idemoi",
  gaTrackingId: "G-XL3ZZN8CP7", //RAR
  defaultUrl: `teacher/lunits`,
  msClarityProjectId: "lqqcfonzz7",

  vapid_public_key: "BPw_jy27Hele_BY01PZb0sttvR_zxNwM3AhlVOOaTwQGRTsWgltgkHD9PqMGnwQVzx-uERzvsjvPs",

  //REGIS API
  node_darate_url: "https://web.infopro.app/darate",
  node_sabs_url: "https://web.infopro.app/sabs",
  base_regis_url: "https://regis-api.opec.go.th",
  school_code: "1114100029",

  //ONEID

  localRegisterInfo: "localRegisterInfo",
  node_test_oneid_url: "https://testoneid.inet.co.th",
  client_id: "877",
  client_secret: "gJvEsneWL2ZILBokpTetD3a",
  ref_code: "ZibuKx",
  oneidDefaultUrl: `sf/menu`

};

import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://imauth.bora.dopa.go.th',
  // URL of the SPA to redirect the user to after login
  redirectUri: `http://localhost:4200`,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'OFdYNTN6ZkEyYkNrbVRpZFZGW45hN00',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: 'SHdGU1VTZGVicWpPSUxSQjhJeWdkag',
 
  responseType: 'code',
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid pid name birthdate',

  showDebugInformation: true,
  logoutUrl: 'http://localhost:4200',

};
