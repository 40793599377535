
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppParameters, AppParametersResponse } from '../models/appParameters';
@Injectable({
  providedIn: 'root'
})
export class ToolsecService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private baseUrl = environment.node_static_url;

  private apiUrl = `${this.baseUrl}/api/v1`;
  private appParametersUrl = `${this.apiUrl}/app/parameters`;

  private appParameters: AppParameters;
  private env: any = {};
  constructor(private http: HttpClient) { 
    this.load();
  }

  getAppParameters():Observable<any>{
    const url = `${this.appParametersUrl}`;
    return this.http.get<AppParametersResponse>(url);
  }

  load() {
    return new Promise((resolve, reject) => {

      this.http.get(`${this.appParametersUrl}`).subscribe((response: any[]) => {
        response.forEach(item => {
          this.env[item.DEFAULT_KEY] = {
            DEFAULT_VALUE: item.DEFAULT_VALUE,
            DEFAULT_DETAIL: item.DEFAULT_DETAIL
          } as AppParametersResponse;
        });
        resolve(true);
      });

    });
  }

  getEnv(key: string) {
    return this.env[key];
  }

  getEnvPromise(key: string) {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.env).length > 0) {
        resolve(this.env[key]);
      } else {
        this.load().then(() => {
          resolve(this.env[key]);
        }, error => {
          reject(error);
        });
      }
    });
  }

}


