import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThaiDateStringPipe } from './app-thaidate.pipe';
import { LinkifyPipe } from './app-linkify.pipe';

@NgModule({
  declarations: [
    ThaiDateStringPipe,
    LinkifyPipe],
  imports: [
    //CommonModule
  ],
  exports: [
    ThaiDateStringPipe,
    LinkifyPipe
  ]
})
export class AppPipesModule { }
