import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { interval, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate) {

    if (!this.swUpdate.isEnabled) {
      console.log('PWA Update is not enabled!!');
    } else {
      console.log('PWA Update is enabled!!');
      interval(6 * 60 * 60).subscribe(() => swUpdate.checkForUpdate()
        .then(() => console.log('PWA checking for updates...')));

      // Subscribe to version updates (new in Angular 17)
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE', 
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      ).subscribe(res => {
        console.log('Update available:', res);
        // Trigger logic to handle the update (e.g., prompt user)
      });
    }
/* 
    this.swUpdate.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    this.swUpdate.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    }); */
 
  }
}
