import { Component } from '@angular/core';
import { environment , authCodeFlowConfig } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { SwPush , VersionReadyEvent} from '@angular/service-worker';
//import { PwaupdateService } from './services/pwaupdate.service';
import { SwUpdate } from '@angular/service-worker'
import { UpdateService } from './services/update.service';
import { UpdateCheckService } from './services/update-check.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PwaInstallService } from './services/pwa-install.service';
import { filter } from 'rxjs/operators';
import { ToolsecService } from './services/toolsec.service';
import { MsClarityService } from './services/ms-clarity.service';

import { OAuthService } from 'angular-oauth2-oidc';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'infoproACE';

  constructor(private meta: Meta, 
    private router: Router, 
    private pwaInstallService: PwaInstallService,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private update: UpdateService,
    private updateCheck: UpdateCheckService, 
    private snackbar: MatSnackBar,
    private toolsecService: ToolsecService,
    private msClarityService: MsClarityService,
    private oauthService: OAuthService) {


    this.addGAScript();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
      /** END */
    })

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.postLogoutRedirectUri= this.oauthService.redirectUri;
    this.oauthService.showDebugInformation= true;
    //this.oauthService.setStorage(sessionStorage);

    // Load Discovery Document and then try to login the user

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
        () => {
          console.log("loadDiscoveryDocumentAndTryLogin");
        // this.router.initialNavigation();
        }).catch(err => {
          console.log("Unable to loadDiscoveryDocumentAndTryLogin");
        })


   /* this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });*/

    this.meta.addTags([
      { name: 'og:title', content: 'InfoproACE' },
      { name: 'og:description', content: 'Check newsfeeds, grades, school fees for students...' }
    ]);

    //event Click of Push Notification
    this.swPush.notificationClicks.subscribe( event => {
      console.log('Received notification: ', event);
      const url = event.notification.data.url;
      window.open(url, '_blank');
    });


    //Listen for launched app
    window.addEventListener('load', () => {
      if (navigator['standalone']) { // 'standalone' in window.navigator
        console.log('PWA Launched: Installed (iOS)');
      } else if (matchMedia('(display-mode: standalone)').matches) {
        console.log('PWA Launched: Installed');
      } else {
        console.log('PWA Launched: Browser Tab');
      }
    }); 


  }

  ngOnInit(): void {

     const snackBarConfig: MatSnackBarConfig = {
      duration: 4000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['snackbar', 'snackbar-green']
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      ).subscribe(() => {
        const snack = this.snackbar.open('Update Available', 'Reload', snackBarConfig);
        snack.onAction().subscribe(() => {
          console.log('Updating to new version...');
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        });
      });
    }

/*      this.swUpdate.available.subscribe(evt => {
      const snack = this.snackbar.open('Update Available', 'Reload', config);
      snack
        .onAction()
        .subscribe(() => {
          console.log('PWA activateUpdate -> reload');
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        //  window.location.reload();
        });
    }); */

    this.pwaInstallService.initPwaPrompt();
    this.toolsecService.load();
    this.msClarityService.injectClarityScript();
  }

  public get mIsAuthen() {
    try {
      let authenInfo = JSON.parse(sessionStorage.getItem(environment.localAuthenInfo))
      if (authenInfo.token != null && authenInfo.token != "") {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  /** Add Google Analytics Script Dynamically */
   addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
    document.head.prepend(gtagScript);
    // Disable automatic page view hit to fix duplicate page view count  
    gtag('config', environment.gaTrackingId, { send_page_view: false });
  }
  addGAScriptFromAppParameter() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
    document.head.prepend(gtagScript);
    // Disable automatic page view hit to fix duplicate page view count  

    let nGaTrackingId = this.toolsecService.getEnv('gaTrackingId').DEFAULT_VALUE;
    console.log('nGaTrackingId=');
    console.log(nGaTrackingId);
    gtag('config', nGaTrackingId, { send_page_view: false });
  } 
}
