import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

//import { StudentsModule} from './students/students.module';
//import { TeacherModule} from './teacher/teacher.module';
//import { MgmtModule} from './mgmt/mgmt.module';

import { LoginComponent } from './components/login/login.component';
import { LoginTeacherComponent } from './components/login-teacher/login-teacher.component';

import { IfHomeComponent } from './if/if-home/if-home.component';
import { TeacherLoginThaidComponent } from './components/teacher-login-thaid/teacher-login-thaid.component';
const routes: Routes = [
 // { path: 'teacher/login', component: LoginTeacherComponent },
  { path: 'teacher', loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule) },
 /* { path: 'mgmt', loadChildren: () => import('./mgmt/mgmt.module').then(m => m.MgmtModule) }, 
   { path: 'student', loadChildren: () => StudentsModule },
  { path: 'teacher', loadChildren: () => TeacherModule },
  { path: 'mgmt', loadChildren: () => MgmtModule }, 
  { path: 'login', component: LoginComponent },*/
  // { path: 'login', component: LoginTeacherComponent },
  { path: 'login', component: LoginTeacherComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
 { path: 'fa', loadChildren: () => import('./fa/fa.module').then(m => m.FaModule) },
 { path: 'sv', loadChildren: () => import('./sv/sv.module').then(m => m.SvModule) },
 { path: 'um', loadChildren: () => import('./um/um.module').then(m => m.UmModule) },
 { path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule) },
 { path: 'bg', loadChildren: () => import('./bg/bg.module').then(m => m.BgModule) },
 { path: 'hri', loadChildren: () => import('./hri/hri.module').then(m => m.HriModule) },
 { path: 'bgest', loadChildren: () => import('./bgest/bgest.module').then(m => m.BgestModule) },
 { path: 'job', loadChildren: () => import('./job/job.module').then(m => m.JobModule) },
 { path: 'rf', loadChildren: () => import('./rf/rf.module').then(m => m.RfModule) },
 { path: 'pr', loadChildren: () => import('./pr/pr.module').then(m => m.PrModule) },
 { path: 'lw', loadChildren: () => import('./lw/lw.module').then(m => m.LwModule) },
 { path: 'hr_emp', loadChildren: () => import('./hr-emp/hr-emp.module').then(m => m.HrEmpModule) },
 { path: 'if', loadChildren: () => import('./if/if.module').then(m => m.IfModule) },
 { path: 'rq', loadChildren: () => import('./rq/rq.module').then(m => m.RqModule) },
 { path: 'ae', loadChildren: () => import('./ae/ae.module').then(m => m.AeModule) },
 { path: 'mp', loadChildren: () => import('./mp/mp.module').then(m => m.MpModule) },
 { path: 'vc_pre', loadChildren: () => import('./vc-pre/vc-pre.module').then(m => m.VcPreModule) },
  { path: '**', redirectTo: '/login' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
