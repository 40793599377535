<nav class="main-header navbar navbar-expand navbar-ace navbar-ace">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a href="index.html" class="nav-link">Home</a>
    </li>
  </ul>

  <!-- SEARCH FORM  
  <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" placeholder="SEARCH" aria-label="Search">
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>
-->

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">

    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="far fa-bell"></i>
        <!-- <span class="badge badge-warning navbar-badge">15</span>-->
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <!--<span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-envelope mr-2"></i> 4 new messages
          <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-file mr-2"></i> 3 new reports
          <span class="float-right text-muted text-sm">2 days</span>
        </a>-->
        <div class="dropdown-divider"></div>
        <a routerLink="/student/notifications" class="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
       <!-- <div class="thumbnail-32">
        <img src="{{node_static_url}}{{nStudent.image_path}}" class="portrait mr-2"  alt="User Image">
      </div>-->
     
       <img src="{{node_static_url}}{{nStudent.image_path}}" class="img-size-32 mr-2 img-circle" alt="User Image"> 
      
        <!--<img src="{{node_static_url}}{{nStudent.image_path}}" class="circle-avatar" alt="User Image">-->
        <!--<div class="circle-avatar-bg" style="background-image:url({{node_static_url}}{{nStudent.image_path}})"></div>-->
        <!--<img src="{{node_static_url}}{{nStudent.image_path}}" class="circle-avatar" alt="User Image">-->
        <span class="hidden-s">{{nStudent.first_name}} {{nStudent.last_name}}</span>
      </a>

      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">

            <img src="{{node_static_url}}{{nStudent.image_path}}" class="img-size-32 mr-3 img-circle"  alt="User Image">
        </span>
        <a span href="#" class="dropdown-item dropdown-header">
          {{nStudent.first_name}} {{nStudent.last_name}}
        </a>

        <div class="dropdown-divider"></div>
        <div class="dropdown-item">
          <button type="button" class="btn btn-block btn-default btn-sm" (click)="onClickLogout()">Log out</button>
        </div>
      </div>
    </li>
    <!-- User Account: style can be found in dropdown.less 
         <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img src="{{node_static_url}}{{nStudent.image_path}}" class="user-image" alt="User Image">
            <span class="hidden-xs">{{nStudent.first_name}} {{nStudent.last_name}}</span>
          </a>
          <ul class="dropdown-menu">

            <li class="user-header">
              <img src="{{node_static_url}}{{nStudent.image_path}}" class="img-circle" alt="User Image">

              <p>
                  {{nStudent.first_name}} {{nStudent.last_name}}
              </p>
            </li>

            <li class="user-footer">
              <div class="pull-left">
                <a href="#" class="btn btn-default btn-flat">Profile</a>
              </div>
              <div class="pull-right">
                <a (click)="onClickLogout()" class="btn btn-default btn-flat">Log out</a>
              </div>
            </li>
          </ul>
        </li>-->
  </ul>
</nav>