import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    } else {
      const started = Date.now();
      return next.handle(req).pipe(
        tap(event => {
          console.log("Logging : ");
          if (event instanceof HttpResponse) {
            const elapsed = Date.now() - started;
            console.log(`[${event.status}] Request for ${req.urlWithParams} took ${elapsed} ms.`);
          }
        }
          , error => {
            if (error instanceof HttpErrorResponse) {
              console.log(`[${error.status}] Request for ${error.error.message}`);
            }
          })
      );
    }
  }
}