import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { StockComponent } from './components/stock/stock.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { LoginTeacherComponent } from './components/login-teacher/login-teacher.component';

import { AuthenService } from './services/authen.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { WebnotificationService } from './services/webnotification.service';
import { httpInterceptorProviders } from './interceptors/interceptors';

//Material Module
import { MaterialModule } from './app.material-module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ReactiveFormsModule } from '@angular/forms';


import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppPipesModule} from './app-pipes/app-pipes.module';
//i18n//
import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';

import { ProgressspinnerComponent } from './components/progressspinner/progressspinner.component';
import { InstallPromptComponent } from './components/install-prompt/install-prompt.component';

import { SharedModule } from './shared/shared.module';
import { CommentsComponent } from './components/comments/comments.component';
import { TeacherLoginThaidComponent } from './components/teacher-login-thaid/teacher-login-thaid.component';
import { OAuthModule,OAuthService } from 'angular-oauth2-oidc';

// import { QuillModule } from 'ngx-quill'

registerLocaleData(localeTh, 'th');

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        StockComponent,
        MenuComponent,
        LoginComponent,
        ProgressspinnerComponent,
        InstallPromptComponent,
        LoginTeacherComponent,
        CommentsComponent,
        TeacherLoginThaidComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        MaterialModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        AppPipesModule,
        MatDatepickerModule,
        AppRoutingModule,
        MatNativeDateModule,
        SharedModule, //should be last
        MatGridListModule,
        ReactiveFormsModule,
        OAuthModule.forRoot()], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'th' },
        { provide: LOCALE_ID, useValue: 'th' },
        httpInterceptorProviders,
        AuthenService, WebnotificationService, OAuthService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
